import WeeklyTimetable from '../timatable/WeeklyTimetable';
import React from 'react';
import { connect } from 'react-redux';
import { getClassesTimetable, getFilledMyTimetable, getSelectedClass } from '../store/app/selectors';
import TimeEntry from './TimeEntry';
import { toggleClass } from '../store/app/actions';

function ClassesTimetable({disabled, timetable, toggleClass, selectedClass}) {
  return <WeeklyTimetable onClickCell={toggleClass} renderer={(i,j) => <TimeEntry selectedClass={selectedClass} disabled={disabled?.[i]?.[j]} entry={timetable?.[i]?.[j]} />}/>
}

const mapStateToProps = state => ({
  timetable: getClassesTimetable(state),
  disabled: getFilledMyTimetable(state),
  selectedClass: getSelectedClass(state),
})

const mapDispatchToProps = dispatch => ({
  toggleClass: (i, j) => dispatch(toggleClass(i, j))
})

export default connect(mapStateToProps, mapDispatchToProps)(ClassesTimetable);
