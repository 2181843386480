import React from 'react';
import styled from 'styled-components'
import { connect } from 'react-redux';
import { getClasses, getSelectedClass } from '../store/app/selectors';
import AddClass from './AddClass';
import { addClass, selectClass } from '../store/app/actions';
import ClassList from './ClassList';

const ClassListWrapper = styled.div`
  margin-bottom: 20px;
`

function Classes({ classes, selectedClass, addClass, selectClass }) {
  return <div>
    <ClassListWrapper>
      <ClassList classes={classes} selectedClass={selectedClass} selectClass={selectClass}/>
    </ClassListWrapper>
    <div>
      <AddClass onAddClass={addClass}/>
    </div>
  </div>
}

const mapStateToProps = state => ({
  classes: getClasses(state),
  selectedClass: getSelectedClass(state),
})

const mapDispatchToProps = dispatch => ({
  addClass: (classData) => dispatch(addClass(classData)),
  selectClass: (name) => dispatch(selectClass(name)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Classes)
