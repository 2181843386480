import React from 'react';
import { Body, Cell, Header, Row, Table } from '../components/Table';
import ClassRow from './ClassRow';

function ClassList({ classes, selectedClass, selectClass }) {
  return <div>
    <Table>
      <Header>
        <Row>
          <Cell>Osztály</Cell>
          <Cell>Szükszéges óraszám</Cell>
          <Cell>Eddigi óraszám</Cell>
        </Row>
      </Header>
      <Body>
        {classes.map(({ name, hours, currentHours }) => <ClassRow key={name} onSelect={selectClass} name={name} hours={hours} currentHours={currentHours} highlighted={selectedClass === name}/>)}
      </Body>
    </Table>
  </div>
}

export default ClassList
