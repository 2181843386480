import React from 'react';
import ClassEntry from './ClassEntry';

function TimeEntry({ disableIsClassReady, disabled, entry, selectedClass }) {
  return <div>{entry && entry.map(className => <ClassEntry disableIsClassReady={disableIsClassReady}
                                                           highlighted={selectedClass === className} key={className}
                                                           name={className}
                                                           disabled={disabled}/>)}</div>;
}

export default TimeEntry;
