const theme = {
  colors: {
    blue: '#4093d4',
    lightBlue: '#50b2ff',
    black: '#333333',
    gray: '#666666',
    lightGray: '#eeeeee',
    white: '#ffffff',
  }
}

export default theme;
