import React from 'react';
import { Body, Cell, Header, Row, Table } from '../components/Table';

const COUNT = 8;

function WeeklyTimetable({ enabled, onClickCell, renderer, ...props }) {

  const cells = [];

  for (let i = 0; i < COUNT; i++) {

    const row = [];

    for (let j = 0; j < 5; j++) {
      row.push(<div>{renderer(i, j)}</div>)
    }

    cells.push(row);
  }

  return <div {...props}>
    <Table>
      <Header>
        <Row>
          <Cell/>
          <Cell>Hétfő</Cell>
          <Cell>Kedd</Cell>
          <Cell>Szerda</Cell>
          <Cell>Csütörtök</Cell>
          <Cell>Péntek</Cell>
        </Row>
      </Header>
      <Body>
        {cells.map((row, i) => <Row key={i}>
          <Cell>{i + 1}</Cell>
          {row.map((cell, j) => <Cell disabled={enabled && !enabled?.[i]?.[j]} key={j}
                                      onDoubleClick={(() => onClickCell(i, j))}>{cell}</Cell>)}
        </Row>)}
      </Body>
    </Table>
  </div>
}

export default WeeklyTimetable;
