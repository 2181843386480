export const getApp = state => state.app;

export const getClasses = state => Object.keys(getApp(state).classes).map(name => ({
  name,
  hours: getApp(state).classes[name].hours,
  currentHours: getCountForClass(state, name),
}));

export const getSelectedClass = state => getApp(state).selectedClass;

export const getClassesTimetable = state => getApp(state).classesTimetable;

export const getMyTimetable = state => getApp(state).myTimetable;

export const getAvailableMyTimetable = state => getSelectedClass(state) ? getClassesTimetable(state).map(day => (day || []).map(items => (items || []).indexOf(getSelectedClass(state)) !== -1)) : null;

export const getFilledMyTimetable = state => getMyTimetable(state).map(day => (day || []).map(item => !!item));

export const isClassReady = (state, className) => getApp(state).classes[className].hours <= getCountForClass(state, className)

export const getCountForClass = (state, className) => getApp(state).myTimetable.reduce((sum, day) => sum + (day || []).reduce((sum, item) => (item && item.indexOf(className) !== -1) ? sum + 1 : sum, 0), 0);

export const getCountForCurrentClass = (state) => getCountForClass(state, getSelectedClass(state));

export const getHoursCount = state => getMyTimetable(state).reduce((sum, day) => sum + (day?.reduce((sum, item) => (item && item.length) ? sum + 1 : sum, 0) || 0), 0);
