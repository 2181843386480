import React from 'react';
import styled from 'styled-components';
import theme from '../theme';

const Wrapper = styled.div`
  background-color: ${theme.colors.blue};
  color: ${theme.colors.white};
  display: inline-block;
  padding: 10px;
  border-radius: 3px;
  cursor: pointer;
  
  &:focus,
  &:hover {
    background-color: ${theme.colors.lightBlue};
  }
`

function Button({children, ...props}) {
  return <Wrapper {...props}>
    {children}
  </Wrapper>
}

export default Button;
