import React from 'react';
import styled from 'styled-components'
import { Provider } from 'react-redux'
import store from './store'
import Classes from './classes/Classes';
import GlobalStyle from './GlobalStyle';
import ClassesTimetable from './classesTimetable';
import MyTimetable from './myTimetable';

const Container = styled.div`
  display: flex;
  width: 100%;
`

const Section = styled.div`
  margin-bottom: 20px;
  padding: 0 10px;
  flex-grow: 1;
  flex-shrink: 1;
`

function App() {
  return (
    <Provider store={store}>
      <GlobalStyle/>
      <Container>
        <Section>
          <Classes/>
        </Section>
        <Section>
          <ClassesTimetable/>
        </Section>
        <Section>
          <MyTimetable/>
        </Section>
      </Container>
    </Provider>
  );
}

export default App;
