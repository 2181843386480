import React from 'react';
import styled from 'styled-components'

const StyledLabel = styled.label`
   margin-bottom: 5px;
   line-height:20px;
   display: block;
   font-size: 1rem;
`

function Label(props) {
  return <StyledLabel {...props}/>
}

export default Label;
