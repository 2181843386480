import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import app from './app';
import { save, load } from "redux-localstorage-simple"

const rootReducer = combineReducers({
  app,
})

const middleware = [save()];

const store = createStore(rootReducer, load(), composeWithDevTools(applyMiddleware(...middleware)))

export default store
