import React from 'react';
import styled from 'styled-components'
import theme from '../theme';

const StyledInput = styled.input`
   margin-bottom: 10px;
   line-height:20px;
   padding: 5px 10px;
   outline: none;
   font-size: 1rem;
   border-radius: 3px;
   border: 1px solid ${theme.colors.gray};
   
   &:focus {
    border-color: ${theme.colors.blue};
   }
`

function Input(props) {
  return <StyledInput {...props}/>
}

export default Input;
