import WeeklyTimetable from '../timatable/WeeklyTimetable';
import React from 'react';
import styled from 'styled-components'
import { connect } from 'react-redux';
import {
  getAvailableMyTimetable,
  getCountForCurrentClass, getHoursCount,
  getMyTimetable,
  getSelectedClass
} from '../store/app/selectors';
import { toggleClassInMyTimetable } from '../store/app/actions';
import TimeEntry from '../classesTimetable/TimeEntry';

const Stat = styled.div`
  min-height: 1em;
  line-height: 1em;
  margin-bottom: 10px;
`

const StyledWeeklyTimetable = styled(WeeklyTimetable)`
  margin-bottom: 10px;
`

function MyTimetable({ enableMatrix, timetable, toggleClass, selectedClass, currentCount, hoursCount }) {
  return <>
    <Stat>{selectedClass && <>Óraszáma az osztálynak ({selectedClass}): {currentCount}</>}</Stat>
    <StyledWeeklyTimetable onClickCell={toggleClass} enabled={enableMatrix} renderer={(i, j) => <TimeEntry disableIsClassReady entry={timetable?.[i]?.[j]}/>}/>
    <Stat>Összes óraszám: {hoursCount}</Stat>
  </>
}

const mapStateToProps = state => ({
  timetable: getMyTimetable(state),
  enableMatrix: getAvailableMyTimetable(state),
  selectedClass: getSelectedClass(state),
  currentCount: getCountForCurrentClass(state),
  hoursCount: getHoursCount(state),
})

const mapDispatchToProps = dispatch => ({
  toggleClass: (i, j) => dispatch(toggleClassInMyTimetable(i, j))
})

export default connect(mapStateToProps, mapDispatchToProps)(MyTimetable);
