import styled from 'styled-components';
import theme from '../theme';

export const Table = styled.table`
  border: 1px solid ${theme.colors.gray};
  border-collapse: collapse;
  width: 100%;
`

export const Header = styled.thead`
  border-bottom: 2px solid ${theme.colors.gray};
`

export const Body = styled.thead`
  border-bottom: 1px solid ${theme.colors.gray};
`

export const Row = styled.tr`
  border: 1px solid ${theme.colors.gray};
  
  color: ${props => props.highlighted ? theme.colors.white : theme.colors.black};
  background-color: ${props => props.highlighted ? theme.colors.blue : theme.colors.white};
`

export const Cell = styled.td`
  border: 1px solid ${theme.colors.gray};
  padding: 5px;
  background-color: ${props => props.disabled ? theme.colors.lightGray : 'none'};
`
