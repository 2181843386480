import styled from 'styled-components';
import React from 'react';
import { connect } from 'react-redux';
import { isClassReady } from '../store/app/selectors';
import theme from '../theme';

const Wrapper = styled.div`
  opacity: ${props => props.disabled ? 0.25 : 1};
  color: ${props => props.highlighted ? theme.colors.blue : 'inherit'};
`;

function ClassEntry({ highlighted, disableIsClassReady, isClassReady, disabled, name, ...props }) {
  return <Wrapper highlighted={highlighted} disabled={!highlighted && (disabled || (!disableIsClassReady && isClassReady))} {...props}>{name}</Wrapper>
}

const mapStateToProps = (state, {name}) => ({
  isClassReady: isClassReady(state, name)
});

export default connect(mapStateToProps)(ClassEntry);
