import { createGlobalStyle } from 'styled-components'
import theme from './theme';

const GlobalStyle = createGlobalStyle`
  html {
    font-family: sans-serif;
    font-size: 16px;
    color: ${theme.colors.black};
  }
`

export default GlobalStyle
