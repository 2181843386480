import { isClassReady } from '../store/app/selectors';
import { connect } from 'react-redux';
import styled from 'styled-components'
import { Cell, Row } from '../components/Table';
import React from 'react';

const StyledRow = styled(Row)`
  opacity: ${props => props.isClassReady ? 0.5 : 1};
`

function ClassRow({ isClassReady, onSelect, name, hours, currentHours, highlighted }) {
  return <StyledRow highlighted={highlighted}
                    isClassReady={isClassReady}
                    onClick={() => onSelect(name)}>
    <Cell>{name}</Cell>
    <Cell>{hours}</Cell>
    <Cell>{currentHours}</Cell>
  </StyledRow>
}

const mapStateToProps = (state, { name }) => ({
  isClassReady: isClassReady(state, name)
});

export default connect(mapStateToProps)(ClassRow);
