import React, { useState } from 'react';
import Button from '../components/Button';
import Input from '../components/Input';
import Label from '../components/Label';

function AddClass({ onAddClass }) {
  const [name, setName] = useState('');
  const [hours, setHours] = useState('');

  return <div>
    <div>
      <Label>Osztály:</Label>
      <Input type="text" value={name} onChange={(e) => setName(e.target.value)}/>
    </div>
    <div>
      <Label>Szükséges óraszám:</Label>
      <Input type="text" value={hours} onChange={(e) => setHours(e.target.value)}/>
    </div>
    <Button onClick={() => {
      onAddClass({
        name,
        hours,
      })
      setName('');
      setHours('');
    }}>Hozzáadom</Button>
  </div>
}

export default AddClass;
