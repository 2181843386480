import { handleActions } from 'redux-actions';
import { addClass, selectClass, toggleClass, toggleClassInMyTimetable } from './actions';

function toggleClassInTimetable(classesTimetable, selectedClass, day, index) {
  const newClassesTimetable = classesTimetable.map(dayArray => dayArray ? dayArray.map(item => item) : []);


  newClassesTimetable[day] = newClassesTimetable[day] || [];

  newClassesTimetable[day][index] = newClassesTimetable[day][index] || [];

  const selectedClassIndex = newClassesTimetable[day][index].indexOf(selectedClass);

  if (selectedClassIndex === -1) {
    newClassesTimetable[day][index].push(selectedClass);
  } else {
    newClassesTimetable[day][index].splice(selectedClassIndex, 1);
  }

  return newClassesTimetable;
}

function toggleClassInMyTimetableHelper(classesTimetable, timetable, selectedClass, day, index) {
  const newTimetable = timetable.map(dayArray => dayArray ? dayArray.map(item => item) : []);

  newTimetable[day] = newTimetable[day] || [];
  newTimetable[day][index] = Array.isArray(newTimetable[day][index]) ? newTimetable[day][index] : [];
  const selectedClassIndex = newTimetable[day][index].indexOf(selectedClass);
  if (selectedClassIndex === -1) {
    if (classesTimetable?.[day]?.[index]?.indexOf(selectedClass) >= 0) {
      newTimetable[day][index].push(selectedClass);
    }
  } else {
    newTimetable[day][index].splice(selectedClassIndex, 1);
  }

  return newTimetable;
}

function fixMyTimetable(myTimetable, newClassesTimetable) {
  const newTimetable = myTimetable.map(dayArray => dayArray ? dayArray.map(item => item) : []);

  return newTimetable.map((day, dayIndex) => day.map((item, itemIndex) => newClassesTimetable?.[dayIndex]?.[itemIndex]?.indexOf(item) >= 0 ? item : null))
}

const classes = handleActions(
  {
    [addClass]: (state, { payload }) => ({
      ...state,
      classes: {
        ...state.classes,
        [payload.name]: {
          hours: payload.hours,
        }
      }
    }),
    [selectClass]: (state, { payload }) => ({
      ...state,
      selectedClass: state.selectedClass === payload ? null : payload,
    }),
    [toggleClass]: (state, { payload: { day, index } }) => {
      const newClassesTimetable = !state.selectedClass ? state.classesTimetable : toggleClassInTimetable(state.classesTimetable, state.selectedClass, day, index)
      return {
        ...state,
        classesTimetable: newClassesTimetable,
        //myTimetable: fixMyTimetable(state.myTimetable, newClassesTimetable)
      }
    },
    [toggleClassInMyTimetable]: (state, { payload: { day, index } }) => ({
      ...state,
      myTimetable: !state.selectedClass ? state.myTimetable : toggleClassInMyTimetableHelper(state.classesTimetable, state.myTimetable, state.selectedClass, day, index)
    }),
  },
  {
    classes: {},
    selectedClass: null,
    classesTimetable: [],
    myTimetable: [],
  }
)

export default classes
